const consts = {
    default_route: '/units',
    fetchAppProps: {
        dispatches: [
            {name: 'fetchUserSettings', params: {}},
            {name: 'fetchUnitsIcons', params: {}},
            {name: 'fetchUnitsHwTypesAll', params: {}},
            {name: 'fetchUnitsTypes', params: {}},

            {name: 'fetchAppUser', params: {}},

            {name: 'fetchAppObjects', params: [
                {name: 'fetchUnitsAll', params: {}},
                {name: 'fetchUnitsLmsgsAll', params: {withAddress: 0}},
                //{name: 'fetchVideoStatusesAll', params: {}},

                {name: 'fetchUnitsGroupsAll', params: {}},

                {name: 'fetchUsersAll', params: {}},
                {name: 'fetchUsersCustomizesAll', params: {}},
            ]},
        ],
    },
    reloadAppProps: {
        dispatches: [
            {name: 'reloadUnitsAll', params: {}},
            {name: 'reloadUnitsLmsgsAll', params: {withAddress: 0}},
            // {name: 'fetchVideoStatusesAll', params: {}},
        ],
        commits: [
        ],
    },
    fetchByTimer: {
        dispatches: [
            //{name: 'fetchUserSettingsChanged', eachT: 30, params: {}},
            //{name: 'fetchUnitsIconsChanged', eachT: 30, params: {}},
            {name: 'fetchUnitsHwTypesChanged', eachT: 30, params: {}},
            //{name: 'fetchUnitsTypesChanged', eachT: 30, params: {}},

            //{name: 'fetchAppUserChanged', eachT: 30, params: {}},

            {name: 'fetchUnitsChanged', eachT: 30, params: {}},
            {name: 'fetchUnitsLmsgsChanged', eachT: 30, params: {withAddress: 0}},
            //{name: 'fetchVideoStatusesAll', eachT: 30, params: {}},

            {name: 'fetchUnitsGroupsChanged', eachT: 30, params: {}},

            {name: 'fetchUsersChanged', eachT: 30, params: {}},
            {name: 'fetchUsersCustomizesChanged', eachT: 30, params: {}},
        ],
    },
    api: {
        user: {
            //fields: [],
            expand: ['units_of_measure', 'settings', 'notifications_settings', 'user_creator', 'is_admin', 'is_super_admin', 'parents', 'dealer_id', 'dealer', 'hide_menu', 'dealers_hide_menu', 'admin_hide_menu', 'dealers_admin_hide_menu', 'head__logo'],
        },

        units_lite: {
            fields: ['id','creator','hw_type','hw_id','name','isRemoved','license','access_right','parent__id'],
            expand: ['user_creator', 'created', 'access_right'], //,counters
        },
        units: {
            //fields: ['id','hw_type','hw_id','name','type','unit_icon','icon','max_speed','phone'],
            expand: ['user_creator', 'created', 'removed', 'access_right', 'license'], //,counters, 'video'
        },

        units_groups_lite: {
            fields: ['id', 'name', 'icon'],
            expand: [],
        },
        units_groups: {
            // fields: ['id',],
            expand: ['access_right'],
        },

        unitslmsgs_lite: {
            fields: ['object_id', 'unit_id', 'id_unit', 'hw_type', 'hw_id', 'time', 'speed', 'course', 'time_speed', 'latlng', 'timeAgo', 'status'],
            //expand: [],
        },
        unitslmsgs: {
            // fields: ['id',],
            expand: ['access_right'],
        },

        users_lite: {
            fields: ['id','creator','login','email','phone','status','status_text'],
            expand: ['user_creator', 'is_admin', 'is_super_admin'],
        },
        users: {
            //fields: ['id','creator','login','email','phone','legal_name','time_zone','dst','status'],
            expand: ['user_creator', 'is_admin', 'is_super_admin', 'parents', 'dealer_id', 'dealer', 'apikeys', 'theme', 'hide_menu', 'dealers_hide_menu', 'admin_hide_menu', 'dealers_admin_hide_menu', 'portal_url', 'admin_url', 'last__login'],//, 'customize'
        },

        customizes_lite:{
            fields: ["id_user","user_id","url","dns","site_name","support_email"],
            expand: [],
        },
        customizes:{
            //fields: [],
            expand: [],
        },

        geopoints_lite: {
            fields: ['id', 'name', 'lat', 'lon', 'icon'],
            // expand: [],
        },
        geopoints: {
            //fields: ['id', 'name', 'descr', 'color', 'line', 'perimeter', 'radius', 'surface_area', 'docs_surface_area', 'maxSpeed'],
            expand: ['access_right'],
        },

        geopoints_groups_lite: {
            fields: ['id', 'name', 'geopoints'],
            // expand: [],
        },
        geopoints_groups: {
            //fields: ['id', 'name', 'descr', 'color', 'line', 'perimeter', 'radius', 'surface_area', 'docs_surface_area', 'maxSpeed'],
            expand: ['access_right'],
        },

        geozones_lite: {
            fields: ['id', 'name', 'color', 'line', 'perimeter', 'radius', 'surface_area', 'docs_surface_area', 'maxSpeed'],
            // expand: [],
        },
        geozones: {
            //fields: ['id', 'name', 'descr', 'color', 'line', 'perimeter', 'radius', 'surface_area', 'docs_surface_area', 'maxSpeed'],
            expand: ['access_right'],
        },

        geozones_groups_lite: {
            fields: ['id', 'name', 'geozones'],
            // expand: [],
        },
        geozones_groups: {
            //fields: ['id', 'name', 'descr', 'color', 'line', 'perimeter', 'radius', 'surface_area', 'docs_surface_area', 'maxSpeed'],
            expand: ['access_right'],
        },

        notifications: {
            //fields: [],
            expand: ['units', 'units-groups', 'geozones', 'geopoints'],
        },

    },
    components: {
        UnitEdit: {
            defaults: {
                options: {
                    components: [
                        'UnitEdit_Options_prometheus',
                        'UnitEdit_Camera_prometheus',
                        'UnitEdit_Locks_prometheus',
                        'UnitEdit_Properties_prometheus',
                    ]
                },
            },
            xCargoUnit: {
                options: {
                    components: [
                        'UnitEdit_Options_prometheus',
                        'UnitEdit_Delivery',
                        'UnitEdit_xCargoSensors',
                        'UnitEdit_License_prometheus',
                    ]
                }
            }
        },
        UserEdit: {
            defaults: {
                options: {
                    components: [
                        'UserEdit_Options_prometheus',
                        'UserEdit_Cost_prometheus',
                        'UserEdit_Info_prometheus',
                        'UserEdit_Password_prometheus',
                    ]
                }
            }
        }
    },
}

export default consts