// import store from '@/store';
// import moment from "moment";
import consts from "@/consts";
const routes = (process.env.VUE_APP_PACKAGE !== 'prometheus.admin') ? [] : [
    {
        path: '/statistics',
        name: consts.routerNames.statistics.main,
        meta: {
            mainRouteName: consts.routerNames.statistics.main,
            bodyGrid: "1X1",
            setRoute: {
                statistics: (route, router) => {
                    if(route.meta?.mainRouteName !== consts.routerNames.statistics.main) {
                        if(this.$isDevelopment) console.warn('statistics', route);
                        return false
                    }
                    router.push({
                        name: consts.routerNames.statistics.main,
                        params: {},
                        query: {}
                    })
                },
                statisticsStatement: (route, router) => {
                    if(route.meta?.mainRouteName !== consts.routerNames.statistics.main) {
                        if(this.$isDevelopment) console.warn('statisticsStatement', route);
                        return false
                    }
                    router.push({
                        name: consts.routerNames.statistics.statement,
                        params: {},
                        query: {}
                    })
                },
            },
        },
        component: () => import('@/views/prometheus/admin/StatisticsPage.vue'),
        children: [
            {
                path: 'statement',
                name: consts.routerNames.statistics.statement,
                meta: {
                    sectionPopupByRoute: {
                        SectionWrapByRoutePopup: {
                            class: 'popup-statement',
                            titlePosition: 'left',
                        }
                    }
                },
                props: {},
                components: {
                    sectionPopupByRoute: () => import('@/components/subscriptions/tables/statementInfo.vue'),
                }
            }
        ]
    },
];

export default routes;